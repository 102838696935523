// import { Moment } from 'moment'

// Redux states
export interface ProductGroup {
  loading: boolean
  filteredProducts?: Product[]
  filters?: []
  isCumulative: boolean
  items: {
    page: number
    limit: number
    pages: number
    total: number
    _links: ProductGroupLinks
    items: Product[]
  }
  stock: []
}

export interface CartContent {
  tokenValue: string
  oneDayDuration?: string
  startLeasingDate: string
  couponCode?: string
  endLeasingDate: string
  productsTotalWeight: number
  channel: string
  currency: string
  locale: string
  checkoutState: string
  items: ProductInCart[]
  totals: Totals
  shippingAddress: ShippingAddress
  billingAddress: BillingAddress
  payments: Payment[]
  shipments: Shipment[]
  cartDiscounts: CartDiscounts
}

export interface Cart {
  loading: boolean
  status?: string
  rmsParcelShopId?: string
  isUpdated?: boolean
  address_type?: string
  error?: {
    message: string
  }
  item?: CartContent
}

export interface ProductsList {
  items: {
    items: Product[]
  }
  loading: boolean
}

export interface CartDiscounts {
  [name: string]: {
    name: string
    amount: {
      current: number
      currency: string
    }
    promotionIndicator: number
  }
}

export interface TaxonGroup {
  loading: boolean
  items: Taxon[]
}

// elements

export interface ProductGroupLinks {
  self?: {
    href: string
  }
  first?: string
  last?: string
  next?: string
}

export interface Product {
  code: string
  name: string
  slug: string
  enabled: boolean
  isSimpleProduct: boolean
  channelCode: string
  maxStock?: number
  metaDescription?: string
  metaKeywords?: string
  description: string
  shortDescription: string
  averageRating: number
  axis?: string[]
  taxons: {
    main: string
    others?: string[]
  }
  variants: Variant[]
  attributes?: string[]
  associations: {
    [name: string]: Product[]
  }
  images: Image[]
  _links: ProductGroupLinks
  sku?: string
  ski?: boolean
}

/**
 * @deprecated - to be deleted, please use Product
 */
export type Products = Product
/*
 * @todo clean up when https://github.com/lizeeco/lizee-wl-frontend/pull/2630 is merged
 */
export type SingleProduct = Products

export interface shortProduct {
  sku: string
  quantity: number
}

export interface Variant {
  code: string
  name: string
  axis: string[]
  tryingOnPrice?: number
  tryingOnRequired?: boolean
  size: string
  nameAxis: {
    [name: string]: string
  }
  available: boolean
  price: Price
  maxStock?: number
  purchase: boolean
  purchasePrice?: number
  images: Image[]
  sku: string
  resalePrice?: {
    goodCondition: number
    perfectCondition: number
    veryGoodCondition: number
  }
  originalPrice?: {
    current: number
    currency: string
  }
  optionValues: any[]
  secondHand?: boolean
}

/**
 * @deprecated
 * use /interface/common/Image instead
 */
export interface Image {
  code: string
  main?: string
  cachedPath: string
  thumb?: string
  medium?: string
  large?: string
  path?: string
}

export interface Taxon {
  code: string
  name: string
  slug: string
  description: string
  position: number
  children?: Taxon[]
  images: Image[]
}

export interface ProductInCart {
  id: number
  quantity: number
  withTryingOn?: boolean
  total: number
  totalWithoutPromotion?: number
  maxStock?: number
  purchase?: boolean
  purchasePrice?: number
  type?: string
  product: Product
  units: Units[]
}

export interface Units {
  isReturned: boolean
  price: number
  productCondition: string
  puid: string
}

export interface StockUnit {
  lizeeSKU: string
  quantity: number
}

export interface Totals {
  total: number
  items: number
  taxes: number
  shipping: number
  promotion: number
  totalItemWithoutPromotion: number
  totalWithCart?: number
  itemsTotalWithCart?: number
  totalWithoutBoughtProducts?: number
  totalSecondHand?: number
}

export interface ShippingAddress {
  firstName: string
  lastName: string
  countryCode: string
  street: string
  details?: string
  provinceName?: string
  city: string
  postcode: string
  phoneNumber?: string
  email?: string
}

export interface BillingAddress {
  firstName: string
  lastName: string
  countryCode: string
  street: string
  details?: string
  city: string
  postcode: string
  phoneNumber?: string
  email?: string
}

export interface Payment {
  state: string
  method: {
    code: string
    name: string
    description: string
    instructions: string
  }
  price: Price
}

export interface Price {
  current: number
  currency: string
}

export interface Shipment {
  state: string
  currency: string
  method: {
    code: string
    name: string
    description: string
    instructions: string
    price: Price
    type: string
    isExpress?: boolean
  }
  // TODO change here for discount management
  cartDiscount?: any
}

export enum GAEvent {
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  PURCHASE = 'purchase',
  PAGE_VIEW = 'page_view',
  VIEW_ITEM = 'view_item',
}

export enum GTMEvent {
  ADD_PAYMENT_INFO = 'add_payment_info',
  PURCHASE = 'purchase',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  PAGE_VIEW = 'page_view',
  VIEW_ITEM = 'view_item',
}

export interface ProductToAdd {
  productCode: string
  quantity?: number
  // Product without variants mustn't send empty variant
  variantCode?: string
  // optionnal if tryout
  withTryingOn?: boolean
  skis?: SkiArray[]
  secondHand?: string
  units?: {
    puid: string
    condition: string
  }[]
}

export interface SkiArray {
  level: string // string, valeur prismic
  size: number // number
  weight: number // number
  shoesSize?: number // number (optionnelle)
}

export interface ProductAvailability {
  available: boolean
  secondHandAvailable: boolean
  secondHandImage: Image[]
}

export interface CheckoutAddress {
  shippingAddress: ShippingAddress
  billingAddress: BillingAddress
}

export interface takeBackData {
  totalAmount: number
  products: takeBackProduct[]
  parcelNumber?: number
  firstname: string
  lastname: string
  company: string
  email: string
  phone: number
  address: string
  addressDetails: string
  zipcode: number
  city: string
  region: string
  country: string
  locale: string
  voucherLocation: string
}

export type takeBackStates = Record<
  'perfectCondition' | 'veryGoodCondition' | 'goodCondition',
  {
    title: string
    desc: string
  }
>

export interface takeBackProduct {
  lizeeSKU: string
  labelReference: string
  amount: number
  quality: string
}

export interface userTakeBackProducts {
  loading: boolean
  response: userProducts[]
}

export interface userProducts {
  id: number
  date: string
  products: productsList[]
}

export interface productsList {
  name: string
  size: string
  color: string
  image: Image[]
  quality: string
}

export interface SecondHandParams {
  puid: string
  condition: string
}

export interface SSOdata {
  loading: boolean
  items: {
    token_type: string
    expires_in: number
    access_token: string
    scope: string
    refresh_token: string
    id_token: string
  }
}

// CMS interfaces

export interface LogoAttr {
  url: string
  alt: string | null
}

export interface ResponsiveImage {
  url: string
  alt: string | null
  lg: {
    url: string
  }
  md: {
    url: string
  }
}

export interface LayoutStyle {
  backgroundColor?: string
  color?: string
  hover?: {
    color?: string
  }
  borderColor?: string
  isTransparent?: boolean
  isMargin?: boolean
  columnTextAlign?: string
  submenuBackgroundColor?: string
  submenuTextColor?: string
  isHomePage?: boolean
  isDisplayedBannerOnAllPages?: boolean
  isBanner?: boolean
  isDisplayedPrice?: boolean
  isZwiterHandCategory?: boolean
  isFocused?: boolean
}

// Nomitatim advanced search

export interface AdvancedRawResultsAddress {
  road?: string
  village?: string
  city?: string
  municipality?: string
  county?: string
  state?: string
  country?: string
  postcode?: string
  country_code?: string
}

export interface AdvancedRawResults {
  filter(arg0?: (result?: any) => boolean)
  place_id?: number
  licence?: string
  osm_type?: string
  osm_id?: number
  boundingbox?: string[]
  lat?: string
  lon?: string
  display_name?: string
  class?: string
  type?: string
  importance?: number
  address?: AdvancedRawResultsAddress
}

export interface UnAvailability {
  startDate: string
  endDate: string
}
